import React from 'react';
import { Link, useStaticQuery, graphql } from "gatsby";
import BlogPost from "src/components/Blog/BlogPost/BlogPost";

import "src/components/Blog/BlogFeatured/_styles.scss";

function BlogFeatured() {
  const data = useStaticQuery(graphql`
    {
      allContentfulBlogPost(limit: 1, filter: {mainPost: {eq: false}, featuredBlog: {eq: true}}, sort: {order: ASC, fields: createdAt}) {
        edges {
          node {
            id
            title
            description
            slug
            carousel {
              carousel {
                file {
                  url
                }
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)
  const blogPosts = data.allContentfulBlogPost.edges;
  return (
    <div className={`blog-featured`}>
      <div className="featured-wrapper">
        {
          blogPosts.map(({node: post}, key) => {
            return (
              <Link to={`/blog/${post.slug}`} key={post.id}>
                <BlogPost data={post} file="url" />
              </Link>
            )
          })
        }
      </div>
    </div>
  )
  return false;
}

export default BlogFeatured
