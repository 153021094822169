import React from "react";
import { Link } from "gatsby";

import 'src/components/Blog/SliderHome/_styles.scss';

const SliderHome = ({ title, description, slug, category, children }) => {
  return (
    <section className='slider-home'>
      <div className="slider-home-wrapper">
        <div className="content">
          <Link to={`/blog/${slug}`}>
            <div className="category">{category}</div>
            <h1>{title}</h1>
            <div className="description">{description}</div>
            <div className="more">Read More <span className="arrow"></span></div>
          </Link>
        </div>
        <div className="slider-principal">
          { children }
        </div>
      </div>
    </section>
  )
}

export default SliderHome
