import React from 'react';
import { Link, useStaticQuery, graphql } from "gatsby"

import "src/components/Blog/BlogTopics/_styles.scss";

function BlogFeatured() {
  const data = useStaticQuery(graphql`
    {
      contentfulBlogHotTopics {
        title
        blogPosts {
          carousel {
            carousel {
              fixed(width: 650) {
                src
              }
            }
          }
          category
          slug
          title
          id
        }
      }
    }
  `)
  const { blogPosts, title } = data.contentfulBlogHotTopics;
  return (
    <div className={`blog-topics`}>
      <div className={`blog-banner-wrapper`}>
        <h2 className="main-title">{title}</h2>
        <div className="content">
          {
            blogPosts.map((post, key) => {
              return (
                <Link to={`/blog/${post.slug}`} key={post.id}>
                  <div className="post">
                    <img src={post.carousel.carousel[0].fixed.src} alt="Mada - Feature" />
                    <p className="category">{post.category}</p>
                    <h3 className="title">{post.title}</h3>
                  </div>
                </Link>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default BlogFeatured
