import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "src/components/Layout/Layout";
import SEO from "src/components/Seo/Seo";

import Slider from "src/components/Slider/Slider";
import SliderHome from "src/components/Blog/SliderHome/SliderHome";
import BlogBanner from "src/components/Blog/BlogBanner/BlogBanner";
import BlogNewsletter from "src/components/Blog/BlogNewsletter/BlogNewsletter";
import BlogTopics from "src/components/Blog/BlogTopics/BlogTopics";
import BlogFeatured from "src/components/Blog/BlogFeatured/BlogFeatured";
import BlogPost from "src/components/Blog/BlogPost/BlogPost";
import BlogLoadMore from "src/components/Blog/BlogLoadMore/BlogLoadMore";

import { fetchPosts } from "src/api/api.js";

import Masonry from 'react-masonry-css'

function BlogPosts({ data }){
  const [posts, setPosts] = useState([]);
  const [limit] = useState(10);
  const [offset, setOffset] = useState(5);
  const [hasLoadMore, setLoadMore] = useState(true);

  useEffect(() => {
    handleLoadMore();
  }, []);

  const blogPosts = data.allContentfulBlogPost.edges;
  const blogMainPost = data.contentfulBlogPost;

  let items = []

  const breakpointColumnsObj = {
   default: 2,
   850: 1
  };

  items = blogPosts.map(function({node:post}, index) {
    if(index === 1){
      return (
        <>
          <BlogPost key={post.id} data={post} />
          <BlogNewsletter />
        </>
      )
    }else {
      return <BlogPost key={post.id} data={post} />
    }
  });

  const handleLoadMore = () => {
    fetchPosts(limit, offset)
      .then(data => {
        setPosts(data.items)
        if(data.total >= offset || data.total === 0){
          setLoadMore(false);
        }
        setOffset(offset+limit)
      });
  }

  return (
    <Layout blackWhiteMenu>
      <SEO
        title="Get Inspired with our Blog"
        description="Get inspired with the Mada Blog by discovering our latest style features, photoshoots and insights. " />
      <div className="blogposts">
        <SliderHome
          category={blogMainPost.category}
          title={blogMainPost.title}
          description={blogMainPost.description}
          slug={blogMainPost.slug} >
            <Slider
              category={blogMainPost.category}
              title={blogMainPost.title}
              description={blogMainPost.description}
              slug={blogMainPost.slug}
              data={blogMainPost.carousel.carousel} />
        </SliderHome>
        <BlogBanner className="mobile" />
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="container"
          columnClassName="my-masonry-grid_column"
        >
          {items}
        </Masonry>
        <BlogFeatured />
        <BlogBanner className="desktop" />
        <BlogTopics />
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="container"
            columnClassName="my-masonry-grid_column"
          >
          {
            posts.map(function(post, index) {
              return (<BlogPost classStyle="wow fadeIn" key={post.sys.id} data={post.fields} file="url" />)
            })
          }
          </Masonry>
          <BlogLoadMore disabled={!hasLoadMore} onClick={handleLoadMore} title="Load More"/>
      </div>
    </Layout>
  );
};
export default BlogPosts;
export const query = graphql`
  query BlogPostsPageQuery {
    allContentfulBlogPost(limit: 5, filter: {mainPost: {eq: false}, featuredBlog: {eq: false}}, sort: {order: DESC, fields: createdAt}) {
      edges {
        node {
          id
          title
          description
          slug
          carousel {
            carousel {
              file {
                url
              }
              fluid {
                src
              }
            }
          }
        }
      }
    }
    contentfulBlogPost(mainPost: {eq: true}){
      id
      title
      description
      slug
      category
      carousel {
        carousel {
          file {
            url
          }
          fluid {
            src
          }
        }
      }
    }
  }
`;
