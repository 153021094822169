import _ from 'lodash';

const API_BASE_URL = 'https://cdn.contentful.com';
const API_SPACE_ID = 'pzqt4mq6t7df';
const API_TOKEN = 'HjGp324tv-1ZcKT2uHAiEUmXYQwzrtmDtE_VKiobX2o';


function getImage(id, data){
  let entryObj = _.find(data.Entry, function(e) {return e.sys.id === id});
  let assetObj = _.find(data.Asset, function(a) {return a.sys.id === entryObj.fields.carousel[0].sys.id});
  return (assetObj.fields.file.url);
}

export const fetchPosts = (limit, offset) => {
  const url = `${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_TOKEN}&order=-sys.createdAt&content_type=blogPost&fields.mainPost=false&fields.featuredBlog=false&limit=${limit}&skip=${offset}`
  return fetch(url)
    .then(response => response.json(). then(data => {
      data.items.map((item, index) => {
        let image = getImage(item.fields.carousel.sys.id, data.includes);
        item.fields.carousel['carousel'] = [];
        item.fields.carousel.carousel.push({file: {url: image}});
      })
      return data;
    }))
}
