import React from "react"
import { Link } from "gatsby"

import "src/components/Blog/BlogLoadMore/_styles.scss";

function BlogLoadMore({title, onClick, disabled}) {
  return (
    <div className="load-more-container">
      <div className="load-more-wrapper">
        <button disabled={disabled} className={`blog-load-more`} onClick={onClick}>
          {title}
        </button>
      </div>
    </div>
  )
}

export default BlogLoadMore
