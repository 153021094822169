import React, { useState, useEffect } from 'react';
import { useStaticQuery } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp'

import "src/components/Blog/BlogNewsletter/_styles.scss";

function BlogNewsletter() {
  const [email, setEmail] = useState('');
  const [successTitle, setSuccessTitle] = useState();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setSuccessTitle(data.contentfulBlogNewsletter.successTitle)
  }, []);

  const handleEmailChange = (event)=> {
    setEmail(event.target.value);
  }

  const handleKeyPress = (event) => {
  if (event.key === 'Enter'){
    submitForm();
  }
}

  const submitForm = () => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(email.toLowerCase())) {
      setSuccess(true);
      addToMailchimp(email)
      .then(data => {
        console.log(data)
        if(data.result === 'error'){
          setSuccessTitle('Oops an error occurred');
        }
      })
      .catch((error) => {
        console.log(error);
      })
    }else{
      console.log('error');
    }
  }

  const data = useStaticQuery(graphql`
    {
      contentfulBlogNewsletter {
        title
        textButton
        successTitle
        placeholder
      }
    }
  `)
  const { title, textButton, placeholder } = data.contentfulBlogNewsletter;
  return (
    <div className={`blog-post blog-newsletter ${success ? 'success' : ''}`}>
      <div className="form">
        <p className="newsletter-title">{title}</p>
        <div className="blog-post__input">
          <input className="newsletter-email" type="text" onChange={handleEmailChange} onKeyPress={handleKeyPress} placeholder={placeholder} />
          <button className="newsletter-button" onClick={submitForm}>{textButton}</button>
        </div>
      </div>
      <div className="success-text">
        <p className="title">{successTitle}</p>
      </div>
    </div>
  )
}

export default BlogNewsletter
