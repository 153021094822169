import React from "react"
import { Link } from "gatsby"

import "src/components/Blog/BlogPost/_styles.scss";

function BlogPost({data, classStyle, file}) {
  const { carousel, title, slug, description } = data;
   return (
    <div className={`blog-post ${classStyle ? classStyle : ''}`}>
      <Link className="link-wrapper" to={`/blog/${slug}`}>
        {
          file === "url" &&
            <img className="blog-post-image" src={carousel.carousel[0].file.url} alt="Mada - App" />
        }
        {
          !file &&
            <img className="blog-post-image" src={carousel.carousel[0].fluid.src} alt="Mada - App" />
        }
        <h3 className="title">{title}</h3>
        <p className="description">{description}</p>
        <span className="more">Read More <span className="arrow"></span></span>
      </Link>
      <hr />
    </div>
  )
}

export default BlogPost
